import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loggedIn : false,
        loggedName : "",
        cartItems : (localStorage.getItem("funCart")===null || localStorage.getItem("funCart")===undefined) ? [] : JSON.parse(localStorage.getItem("funCart"))
    },
    mutations: {
        setLogin(state,data) {
            state.loggedIn = data.status;
            state.loggedName = data.name;
        },
        setLoginName(state,name) {
            state.loggedName = name;
        },
        setLogout(state) {
            state.loggedIn = false;
            state.loggedName = "";
        },
        clearCart(state) {
            localStorage.setItem("funCart", JSON.stringify([]));
            state.cartItems = JSON.parse(localStorage.getItem("funCart"));
        },
        removeFromCart(state,article) {
            if (!localStorage.getItem("funCart")) {
                localStorage.setItem("funCart", JSON.stringify([]));
            }
            const cartItems = JSON.parse(localStorage.getItem("funCart"));
            const index = cartItems.findIndex(({ id }) => id === article.id);
            cartItems.splice(index, 1);
            localStorage.setItem("funCart", JSON.stringify(cartItems));
            state.cartItems = JSON.parse(localStorage.getItem("funCart"));
        },
        updateCartQty(state,article) {
            if (!localStorage.getItem("funCart")) {
                localStorage.setItem("funCart", JSON.stringify([]));
            }
            const cartItems = JSON.parse(localStorage.getItem("funCart"));
            const found = cartItems.find(({ id }) => id === article.id);
            if(found) {
                found.qty = parseInt(article.qty);
                localStorage.setItem("funCart", JSON.stringify(cartItems));
            }
            state.cartItems = JSON.parse(localStorage.getItem("funCart"));
        },
        addToCart(state,data) {
            const article = data.item;
            if (!localStorage.getItem("funCart")) {
                localStorage.setItem("funCart", JSON.stringify([]));
            }
            const cartItems = JSON.parse(localStorage.getItem("funCart"));
            const found = cartItems.find(({ id }) => id === article.id);
            if(found) {
                found.qty += parseInt(article.qty);
                localStorage.setItem("funCart", JSON.stringify(cartItems));
            } else {
                const item = {
                    id : article.id,
                    qty : article.qty,
                    name : article.name,
                    price : data.price,
                    realPrice : article.realPrice,
                    image : article.imageUrl,
                    tax : article.tax,
                    category : article.category.name,
                    offer : data.offer,
                };
                cartItems.push(item);
                localStorage.setItem("funCart", JSON.stringify(cartItems));
            }
            state.cartItems = JSON.parse(localStorage.getItem("funCart"));
        }
    }
})

export default store;
