<template>
  <div class="col-12  col-sm-12 col-md-6 col-lg-4 mb-3">
    <div class="w-100 d-flex flex-column h-100 overflow-hidden p-3 rounded-lg bg-white rounded-3 shadow-sm">
      <div class="row">
         <div class="col-12" v-if="offer.haveOffer">
             <span class="offer-tag on">{{offer.name}}</span>
             <span class="offer-tag ov">{{offer.value}}% OFF</span>
         </div>
        <div class="col-12">
          <b-img-lazy class="w-100" :src="ticket.image" />
        </div>
      </div>
      <div class="row justify-content-center flex-grow-1">
        <div class="col-12 py-3">
          <b class="text-black">{{ticket.title}}</b>
          <p class="text-muted mt-2">
            {{ticket.info}}
          </p>
          <b-alert :show="ticket.customDate.enable==='1' && ticket.customDate.info!==''" variant="warning" class="m-0 px-2 py-1">
            <small>{{ticket.customDate.info}}</small>
          </b-alert>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 pb-3" v-if="ticket.price > 0">
          <h5 class="m-0">
            <strong class="m-0 text-yellow">
              <small v-if="!ticket.isSinglePrice">{{$t('startingFrom')}}</small> {{$helper.convertToCurrency($helper.afterOfferDiscount(ticket.price,offer))}}
              <del class="text-danger" v-if="offer.haveOffer">
                <small>
                  {{$helper.convertToCurrency(ticket.price)}}
                </small>
              </del>
            </strong>
          </h5>
        </div>
        <div class="col-12">
          <router-link custom
                       :to="'/buyTicket/'+ticket.code" v-slot="{ href, route, navigate }">
            <b-button block :href="href" @click="navigate"
                      variant="yellow">{{$t('bookNow')}}<b-icon-arrow-right-short /> </b-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingleTicket",
  props : {
    ticket : Object,
    offer : Object
  }
}
</script>
<style scoped>

</style>
