import Vue from "vue";

// const numberPattern = /^\d+$/;
// const patternGreaterZero = /^[1-9]\d*$/;
// const euPattern = /^[0-9]+(,[0-9]{1,2})?$/;

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const euFormatter = new Intl.NumberFormat(
    'de-DE', {
        style: 'currency', currency: 'EUR' });

const eu = new Intl.NumberFormat('de-DE',
    { style: 'decimal', minimumFractionDigits: 2 }
    );

var helper = {
    convertToCurrency(number) {
        return euFormatter.format(number);
    },
    usToEuSplitter(input) {
        return input.replace(/[,.]/g, function (x) { return x === "," ? "." : ","; });
    },
    euToUs(input) {
        return input.replace(/[,.]/g, function (x) { return x === "," ? "." : ""; });
    },
    convertToEu(number) {
        return eu.format(number);
    }, validateEmail(mail) {
        return emailPattern.test(mail);
    },setLogin(token) {
        localStorage.setItem("FpCusAuthToken",token);
    },
    setLogOut() {
        localStorage.setItem("FpCusAuthToken",'');
    },
    roomClass (room,slot,slotNumber,isDayClosed,thisTime,selectedSlots,isClickable) {
        const closed = isDayClosed.close;
        const index = selectedSlots.indexOf(slotNumber);
        let time = 0;
        if(slotNumber==="1") {
            time = 630;
        } else if(slotNumber==="2") {
            time = 840;
        } else {
            time = 1020;
        }
        return {
            'cursor' : (isClickable),
            'roomSlotSelected' : (index!==-1),
            "roomSlotClosed" : (room.open===0 || slot===0 || time<thisTime || closed),
            "roomSlotOpened" : (room.open===1 && slot===1 && time>thisTime && !closed),
        }
    },
    afterOfferDiscount(amount,offer) {
        let afterDiscount = amount;
        if(offer.haveOffer) {
            afterDiscount = amount - (amount * offer.value / 100);
        }
        return afterDiscount;
    },
    discountAmount(amount,offer) {
        let discountAmount = 0;
        if(offer.haveOffer) {
            discountAmount = (amount * offer.value / 100);
        }
        return discountAmount;
    },
    showSuccess(message)
    {
        Vue.swal({
            icon: 'success',
            title: message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
            }
        });
    },
    showError(message)
    {
        Vue.swal({
            icon: 'error',
            title: message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Vue.swal.stopTimer)
                toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
            },
        });
    },
    showLoader(message="") {
        const text = (message==="" || message===undefined) ? 'Processing' : message;
        Vue.swal.fire({
            title : '',
            text : text,
            allowOutsideClick : false,
            allowEscapeKey : false,
            // allowEnterKey : false,
            showConfirmButton : false,
            width : "16rem",
            willOpen: () => {
                Vue.swal.showLoading()
            },
            showClass : {
                popup : "swalBlurBody",
            }
        });
    },
    hideLoader() {
        Vue.swal.close();
    },
}

export default helper;
