<template>
  <b-modal id="registerModal" no-stacking
           lazy
           :no-close-on-esc="saving"
           :no-close-on-backdrop="saving"
           body-class="p-0" hide-header hide-footer centered no-fade>

    <div class="text-center py-3">
      <h4>{{$t("register")}}</h4>
    </div>
    <form @submit.prevent @submit="onRegister" class="col-12">

      <div class="form-row">
        <b-form-group class="col-12 col-sm-6" :label="$t('firstName')">
          <b-form-input v-model="form.firstName" />
        </b-form-group>

        <b-form-group class="col-12 col-sm-6" :label="$t('lastName')">
          <b-form-input v-model="form.lastName" />
        </b-form-group>

        <b-form-group class="col-12" label="E-Mail">
          <b-form-input v-model="form.email" />
        </b-form-group>

        <b-form-group class="col-12" :label="$t('phoneNumber')">
          <div class="form-row">
            <div class="col-4">
              <country-select className="form-control"
                              :country="form.country"
                              :autocomplete="true"
                              v-model="form.country"
                              :usei18n="false"/>
            </div>
            <div class="col-8">
              <b-form-input v-model="form.phoneNumber" placeholder="z.b : 0987654321" />
            </div>
          </div>
        </b-form-group>


        <b-form-group class="col-12 col-sm-8" :label="$t('street')">
          <b-form-input v-model="form.street" />
        </b-form-group>
        <b-form-group class="col-12 col-sm-4" :label="$t('doorNumber')">
          <b-form-input v-model="form.houseNumber" />
        </b-form-group>

        <b-form-group class="col-12 col-sm-8" :label="$t('city')">
          <b-form-input v-model="form.city" />
        </b-form-group>
        <b-form-group class="col-12 col-sm-4" :label="$t('postalCode')">
          <b-form-input v-model="form.postalCode" />
        </b-form-group>


        <b-form-group class="col-12 col-sm-6" :label="$t('password')">
          <b-form-input type="password" v-model="form.password" autocomplete="new-password" />
        </b-form-group>
        <b-form-group class="col-12 col-sm-6" :label="$t('confirmPassword')">
          <b-form-input type="password" v-model="form.reEnterPassword" autocomplete="new-password"  />
        </b-form-group>

        <b-form-group class="col-12">
          <b-form-checkbox name="agreeAgb"
                           v-model="form.agreeAgb"
                           value="1" unchecked-value="0">
            <span v-html="$t('agreeOurAgb')"></span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-12">
          <b-form-checkbox name="subscribe"
                           v-model="form.subscribe"
                           value="1" unchecked-value="0">
            <span v-html="$t('subscribe')"></span>
          </b-form-checkbox>
        </b-form-group>

        <b-form-group class="col-12">
          <b-button block variant="yellow" type="submit"
                    :disabled="saving">
            <b-spinner small class="mr-2" v-if="saving" />
            {{$t('signUp')}}
          </b-button>
        </b-form-group>

      </div>
    </form>



    <b-form-group class="col-12">
      <b-alert :show="error" variant="danger">
        {{errorMessage}}
      </b-alert>
    </b-form-group>



    <b-form-group class="col-12 text-center">
      {{$t("alreadyHaveAccount")}}
      <a class="cursor noWrap" v-b-modal:loginModal>{{$t("login")}}</a>

      <div class="w-100 py-1"></div>
      <a class="text-muted" href="https://www.flipped-funpark.de/kopie-von-impressum" target="_blank" style="text-decoration: underline;">
        <small>{{$t("termsAndCondition")}}</small>
      </a>
    </b-form-group>
    <div class="col-12 text-center py-3 cursor text-danger border-top"
         @click="closeModal">
      <b-icon-x-circle font-scale="1.4" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AuthRegister",
  methods : {
    onRegister() {
      this.saving = true;
      this.error = false;
      this.$http.post("front/register",this.form).then(response=>{
        if(response.data.error===undefined) {
          this.error = true;
          this.errorMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.error = true;
          this.errorMessage = response.data.message;
        } else {
          this.$bvModal.hide("registerModal");
          this.$store.commit("setLogin",{
            status : true,
            name : response.data.name
          });
          this.$helper.setLogin(response.data.token);
          this.form = {
            firstName : "",
            lastName : "",
            email : "",
            country: '',
            phoneNumber : "",
            street : "",
            houseNumber : "",
            city : "",
            postalCode : "",
            password : "",
            reEnterPassword : "",
            agreeAgb : "0",
            subscribe : "0"
          };
        }
      }).catch((error)=>{
        this.error = true;
        this.errorMessage = error.toString();
      }).finally(()=>{
        this.saving = false;
      });
    },
    closeModal() {
      this.$bvModal.hide("registerModal");
    }
  },
  data() {
    return {
      saving : false,
      error : false,
      errorMessage : "",
      form : {
        firstName : "",
        lastName : "",
        email : "",
        country: '',
        phoneNumber : "",
        street : "",
        houseNumber : "",
        city : "",
        postalCode : "",
        password : "",
        reEnterPassword : "",
        agreeAgb : "0",
        subscribe : "0"
      }
    }
  }
}
</script>

<style scoped>

</style>
