<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.checkUserAuth();
  },
  methods : {
    checkUserAuth() {
      const token = localStorage.getItem("FpCusAuthToken");
      if(token!==null && token!=='') {
        this.$http.post("front/validateToken").then(response=>{
          if(response.data.error || response.data.error===undefined) {
            this.$store.commit("setLogout");
          } else {
            this.$store.commit("setLogin",{
              status : true,
              name : response.data.name
            });
          }
        }).catch(()=>{
          this.$store.commit("setLogout");
        });
      } else {
        this.$store.commit("setLogout");
      }
    }
  },
  data() {
    return {
      loading : true,
    }
  }
}
</script>

<style>
.splashScreen {
  position: fixed;
  height: 100%; width: 100%; left: 0; top: 0;
  z-index: 100;
  background: #08082a;
  color: whitesmoke;
}
.splashCenter {
  position: relative;
  margin: 0 auto;
  display: block; height: 150px; line-height: 150px;
  width: 150px; text-align: center;
}
.splashCenter:after {
  content: ''; left: 0;
  position: absolute; height: 150px; width: 150px;
  border-radius: 100%;
  border-right: 4px solid red;
  border-left: 4px solid yellow;
  border-top: 4px solid green;
  border-bottom: 4px solid whitesmoke;
  -webkit-animation-name: Rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: Rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}
.splashScreen  img {
  width: 100px;
}


@-webkit-keyframes Rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes Rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes Rotate {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
</style>
