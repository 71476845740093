<template>

<!--  <scroll-fixed-header :fixed.sync="fixed" :threshold="56">-->
    <div class="pageHeader clearfix py-2 d-print-none">
      <div class="container">
        <div class="form-row">

          <div class="col logoWrap text-center text-lg-left order-0">
            <a href="https://www.flipped-funpark.de/">
              <img src="../../assets/logo.jpeg" class="brandLogo" />
            </a>
          </div>
          <div class="col-12 text-center d-block d-lg-none">
            <div class="navContainer">
              <ul class="mainNavigation">
                <li class="menu-tickets"><router-link to="/">Tickets</router-link></li>
              </ul>
            </div>
          </div>
          <!--<div class="col-12 col-lg-auto order-2 order-lg-1 d-none d-lg-block" ref="mainNav">
            <ul class="mainNavigation">
              <li><a href="https://www.flipped-funpark.de/geburtstage">GEBURTSTAGE</a></li>
              <li><a href="https://www.flipped-funpark.de/events01" target="_blank">{{$t("events")}}</a></li>
              <li><a href="https://www.fear-horrorwalk.de/" target="_blank">{{$t("horrorwalk")}}</a></li>
              <li><router-link to="/shop">Shop</router-link></li>
              <li><router-link to="/">Tickets</router-link></li>
              <li><router-link to="/buy-voucher">{{$t('buyVoucher')}}</router-link></li>
            </ul>
          </div>-->
          <div class="col-12 col-lg-auto">
            <div class="row justify-content-center">

              <div class="col-auto order-2 order-lg-2 my-auto pr-0">

                <div class="form-row topAccMenuBox cursor text-white border rounded-lg position-relative"
                     v-if="$store.state.loggedIn">
                  <div class="col-auto mt-1">
                    <b-icon-person-circle font-scale="1.5" />
                  </div>
                  <div class="col pr-3 text-center d-none d-lg-block">
                    <b>{{$store.state.loggedName}}</b>
                    <div></div>
                    <small>{{$t('myAccount')}}</small>
                  </div>
                  <ul class="topProfileMenu">
                    <li><router-link to="/profile">{{$t('profile')}}</router-link></li>
                    <li><router-link to="/profile/orders">{{$t('myBookings')}}</router-link></li>
                    <li><a @click="logOut">{{$t('logOut')}}</a></li>
                  </ul>
                </div>
                <a v-else class="profileMenu cursor" @click="$bvModal.show('loginModal')">
                  <b-icon-person-circle font-scale="1.5" />
                </a>
              </div>

              <div class="col-auto col-lg-auto order-1 order-lg-1 pr-0">
                <ul class="mainNavigation">
                  <li>
                    <router-link to="/shop/checkOut">
                      <b-icon-cart-fill font-scale="1.5" />
                      <b-badge variant="danger">
                        {{$store.state.cartItems.length}}
                      </b-badge>
                    </router-link>
                  </li>
                </ul>
              </div>

              <!--<div class="col-auto order-3 my-auto d-block d-lg-none">
              <button type="button" class="navbar-toggler collapsed"
               @click="toggleMenu">
                <b-icon-list font-scale="1.5" class="openMobileMenu text-yellow" />
              </button>
              </div>-->

                <div class="col-auto order-3 my-auto d-block d-lg-none">

                    <a class="menuCloseOpenButton menuOpenButton" @click="menuOpened">
                      <span class="menuButtonLineOne"></span>
                      <span class="menuButtonLineTwo"></span>
                      <span class="menuButtonLineThree"></span>
                    </a>

                </div>

            </div>
          </div>


          <div class="col-12" ref="mainNav">
            <div id="mySidePanel" class="sidePanel" ref="sidePanel">
              <a class="menuCloseOpenButton menuCloseButton" @click="menuOpened"></a>
              <div class="navContainer">
                <ul class="mainNavigation">
                  <li><a href="https://www.flipped-funpark.de">Home</a></li>
<!--                  <li><a href="https://www.flipped-funpark.de/attraktionen">ATTRAKTIONEN</a></li>-->
<!--                  <li><a href="https://www.flipped-funpark.de/geburtstage">GEBURTSTAGE</a></li>-->
<!--                  <li><a href="https://www.flipped-funpark.de/events01" target="_blank">{{$t("events")}}</a></li>-->
<!--                  <li><a href="https://www.fear-horrorwalk.de/" target="_blank">{{$t("horrorwalk")}}</a></li>-->
<!--                  <li><router-link to="/shop">Shop</router-link></li>-->
                  <!--<li><router-link to="/buy-voucher">{{$t('buyVoucher')}}</router-link></li>-->
                  <li class="d-block d-lg-none"><router-link to="/">Tickets</router-link></li>
                  <li class="menu-tickets d-none d-lg-block"><router-link to="/">Tickets</router-link></li>
                </ul>
              </div>
            </div>

          </div>


        </div>

      </div>

      <auth-login />
      <auth-reset-password />
      <auth-register />

    </div>
<!--  </scroll-fixed-header>-->

</template>



<script>
import AuthLogin from "@/components/Auth/AuthLogin";
import AuthResetPassword from "@/components/Auth/AuthResetPassword";
import AuthRegister from "@/components/Auth/AuthRegister";

export default {
  name: "pageHeader",
  components: {AuthRegister, AuthResetPassword, AuthLogin},
  watch:{
    $route (){
      if(this.isMobile)
        this.$refs.sidePanel.classList.remove("opened");
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth()
    })
  },
  methods : {
    getWindowWidth() {
      const windowWidth = document.documentElement.clientWidth;
      if(windowWidth<=992) {
        this.isMobile = true;
        if (this.$refs.sidePanel !== undefined) {
          this.$refs.sidePanel.classList.remove("opened");
        }
      }
      // else {
      //   this.isMobile = false;
      //   this.$refs.mainNav.classList.remove("d-none");
      // }
    },
    logOut() {
      this.$helper.setLogOut();
      this.$store.commit("setLogin",false);
      if(this.$route.meta.requireAuth) {
        this.$router.push("/");
      }
    },
    toggleMenu() {
      if(this.isMobile) {
        this.$refs.sidePanel.classList.toggle("opened");
      }
    },
    menuOpened() {
      this.toggleMenu();
    }
  },
  data() {
    return {
      isMobile : false,
      fixed: false,
    }
  }
}


</script>



<style scoped>
.topProfileMenu {
  display: none;
  position: absolute; z-index: 10;
  top: 100%;
  width: 200px;
  right: 0; list-style: none; padding: 0; list-style: none;
  background: #FFFFFF;
  border-radius: 0 0 2px 2px;
  box-shadow: 0px 0px 3px #C1C1C1;
  overflow: hidden;
}
.topAccMenuBox:hover .topProfileMenu {
  display: block;
}
.topProfileMenu li {
  display: block;
}
.topProfileMenu li a {
  display: block; cursor: pointer;
  padding: 10px; color: #000000;
}
.topProfileMenu li a:hover {
  color: #000000;
  background: #F1F1F1;
}

.brandLogo {
  width: 150px;
}
.pageHeader {
  background: #000000;
}
.is-fixed .pageHeader{
  box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 25%), inset 0 -1px 5px rgb(0 0 0 / 25%);
}
.mainNavigation {
  display: block; width: 100%; float: left;
  margin: 0; padding: 0; list-style: none;
}
.mainNavigation li {
  display: block; float: left;
}
.mainNavigation li a {
  display: block; cursor: pointer; color: #FFFFFF;
  line-height: 60px; padding: 0 10px; text-transform: uppercase; letter-spacing: 3.5px;
  font-size: 12.4px; font-weight: 700; transition: .4s;
}
.mainNavigation li a:hover {
  color: #df3311;
}
</style>
