<template>
  <b-modal size="sm" id="loginModal" no-stacking
           :no-close-on-esc="saving" lazy
           :no-close-on-backdrop="saving"
           body-class="p-0" hide-header hide-footer centered no-fade>

    <div class="text-center py-3">
      <h4>{{$t("login")}}</h4>
    </div>
    <form @submit.prevent @submit="onLogin">
      <b-form-group class="col-12" label="E-Mail">
        <b-form-input autocomplete="username" v-model="form.email" />
      </b-form-group>

      <b-form-group class="col-12" label="Passwort">
        <b-form-input autocomplete="current-password" type="password" v-model="form.password" />
      </b-form-group>


      <b-form-group class="col-12">
        <a class="text-danger cursor openForgetPassword"
        v-b-modal:forgotPasswordModal>
          {{$t("forgotPassword")}}
        </a>
      </b-form-group>

      <b-form-group class="col-12">
        <b-button block variant="yellow" type="submit"
                  :disabled="saving">
          <b-spinner small class="mr-2" v-if="saving" />
          {{$t("login")}} <b-icon-box-arrow-in-right />
        </b-button>
      </b-form-group>
    </form>



    <b-form-group class="col-12">
      <b-alert :show="error" variant="danger">
        {{errorMessage}}
      </b-alert>
    </b-form-group>

    <b-form-group class="col-12 text-center">
      {{$t("haveNoAccount")}}
      <a class="cursor noWrap"
         @click="$bvModal.show('registerModal')">{{$t("register")}}</a>

      <div class="w-100 py-1"></div>
      <a class="text-muted" target="_blank" href="https://www.flipped-funpark.de/kopie-von-impressum" style="text-decoration: underline;">
        <small>{{$t("termsAndCondition")}}</small>
      </a>
    </b-form-group>
    <div class="col-12 text-center py-3 cursor text-danger border-top"
    @click="closeModal">
      <b-icon-x-circle font-scale="1.4" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AuthLogin",
  methods : {
    closeModal() {
      this.$bvModal.hide("loginModal");
      this.form = {
        email : "",
        password : "",
      };
      this.saving = false;
      this.error = false;
      this.errorMessage = "";
    },
    onLogin() {
      this.saving = true;
      this.error = false;
      this.$http.post("front/login",this.form).then(response=>{
        if(response.data.error===undefined) {
          this.error = true;
          this.errorMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.error = true;
          this.errorMessage = response.data.message;
        } else {
          this.$bvModal.hide("loginModal");
          this.$store.commit("setLogin",{
            status : true,
            name : response.data.name
          });
          this.$helper.setLogin(response.data.token);
          this.form = {
            email : "",
            password : "",
          };
        }
      }).catch(error=>{
        this.error = true;
        this.errorMessage = error.toString();
      }).finally(()=>{
        this.saving = false;
      });
    }
  },
  data() {
    return {
      form : {
        email : "",
        password : "",
      },
      saving : false,
      error : false,
      errorMessage : "",
    }
  }
}
</script>

<style scoped>

</style>