<template>
  <b-modal size="sm" id="forgotPasswordModal"
           no-stacking lazy
           :no-close-on-esc="saving"
           :no-close-on-backdrop="saving"
           body-class="p-0" hide-header hide-footer centered no-fade>

    <div class="text-center py-3">
      <h4>{{$t("forgotPassword")}}</h4>
    </div>

    <form @submit.prevent @submit="onFormSubmit">
      <b-form-group class="col-12" label="E-Mail">
        <b-form-input v-model="email" :disabled="step===2" />
      </b-form-group>

      <div class="col-12" v-if="step===2">
        <label>{{$t("enterOtp")}}</label>
        <div class="form-row mb-3">
          <div class="col">
            <b-form-input v-model="otp[0]" class="otpBox" maxlength="1" />
          </div>
          <div class="col">
            <b-form-input v-model="otp[1]" class="otpBox" maxlength="1" />
          </div>
          <div class="col">
            <b-form-input v-model="otp[2]" class="otpBox" maxlength="1" />
          </div>
          <div class="col">
            <b-form-input v-model="otp[3]" class="otpBox" maxlength="1"/>
          </div>
        </div>

        <div class="form-row">
          <b-form-group class="col-12" :label="$t('password')">
            <b-form-input type="password" v-model="password" />
          </b-form-group>

          <b-form-group class="col-12" :label="$t('confirmPassword')">
            <b-form-input type="password" v-model="reEnterPassword" />
          </b-form-group>

          <b-form-group class="col-12">
            <a @click="step=1" class="cursor text-danger">{{$t('changeEmail')}}</a>
          </b-form-group>

        </div>
      </div>


      <b-form-group class="col-12">
        <b-button block variant="yellow" type="submit"
                  :disabled="saving">
          <b-spinner small class="mr-2" v-if="saving" />
          {{$t("reset")}}
        </b-button>
      </b-form-group>

      <b-form-group v-if="step===2" class="col-12 text-center">
        <a v-if="countDown>0">Resend In {{countDown}}s</a>
        <a @click="onRequest" class="text-info cursor" v-else>{{$t("resend")}}</a>
      </b-form-group>
    </form>

    <b-form-group class="col-12">
      <b-alert :show="state==='error'"
               variant="danger">
        {{errorMessage}}
      </b-alert>
      <b-alert :show="state==='success'" variant="info">
        {{errorMessage}}
      </b-alert>
    </b-form-group>

    <b-form-group class="col-12 text-center">
      {{$t("alreadyHaveAccount")}}
      <a class="cursor noWrap" v-b-modal:loginModal>{{$t("login")}}</a>

      <div class="w-100 py-1"></div>
      <a class="text-muted" href="https://www.flipped-funpark.de/kopie-von-impressum" target="_blank" style="text-decoration: underline;">
        <small>{{$t("termsAndCondition")}}</small>
      </a>
    </b-form-group>
    <div class="col-12 text-center py-3 cursor text-danger border-top"
    @click="closeModal">
      <b-icon-x-circle font-scale="1.4" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "AuthResetPassword",
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId==="forgotPasswordModal")
        this.resetAllParam();
    });
  },
  methods : {
    closeModal() {
      this.step = 1;
      this.state = "";
      this.$bvModal.hide("forgotPasswordModal");
      this.resetAllParam();
    },
    resetAllParam() {
      this.email = "";
      this.password = "";
      this.reEnterPassword = "";
      this.otp = ["","","",""];
    },
    onFormSubmit() {
      if(this.step===1) {
        this.state = "";
        this.onRequest();
      } else {
        this.state = "";
        this.onChange();
      }
    },
    onChange() {
      this.saving = true;
      const param = {
        email : this.email,
        password : this.password,
        reEnterPassword : this.reEnterPassword,
        otp : this.otp.join("")
      };
      this.$http.post("front/resetPassword",param).then(response=>{
        if(response.data.error===undefined) {
          this.state = "error";
          this.errorMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.state = "error";
          this.errorMessage = response.data.message;
        } else {
          this.state = "success";
          this.errorMessage = response.data.message;
          this.resetAllParam();
          setTimeout(() => {
            this.$bvModal.show("loginModal");
          }, 1000);
        }
      }).catch(error=>{
        this.state = "error";
        this.errorMessage = error.toString();
      }).finally(()=>{
        this.saving = false;
      });
    },
    onRequest() {
      this.step = 1;
      this.saving = true;
      this.responseCode = 0;
      this.$http.post("front/requestPasswordChange",{
        email : this.email
      }).then(response=>{
        if(response.data.error===undefined) {
          this.state = "error";
          this.errorMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.state = "error";
          this.errorMessage = response.data.message;
        } else {
          this.state = "success";
          this.errorMessage = response.data.message;
          this.step = 2;
          this.countDownTimer();
        }
      }).catch(error=>{
        this.state = "error";
        this.errorMessage = error.toString();
      }).finally(()=>{
        this.saving = false;
      });
    },
    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
  data() {
    return {
      countDown : 5,
      step : 1,
      saving : false,
      email : "",
      password : "",
      reEnterPassword : "",
      otp : ["","","",""],
      state : "",
      errorMessage : ""
    }
  }
}
</script>

<style>
.otpBox {
  text-align: center !important;
  font-weight: 500;
}
</style>