<template>
<div class="appFooter py-5 d-print-none">
  <div class="footerCredits clearfix w-100 py-2 text-center text-muted">
    <router-link to="/download-voucher">{{$t('downloadVoucher')}}</router-link>
    <a href="https://www.flipped-funpark.de/infos-1" target="_blank">AGB</a>
    <a href="https://www.flipped-funpark.de/kopie-von-faq" target="_blank">Impressum</a>
    <a>{{new Date().getFullYear()}} Flipped FunPark</a>
    <a href="https://www.facebook.com/flipped.funpark/" target="_blank">
      <b-icon-facebook />
    </a>
    <a href="https://www.instagram.com/flipped.fun/" target="_blank">
      <b-icon-instagram />
    </a>
    <a href="https://www.flipped-funpark.de/kopie-von-impressum" target="_blank">Datenschutz</a>

  </div>
  <!-- Designed and Maintained by Snowoffice GmbH Technology Partner Snowoffice GmbH -->
</div>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
.appFooter {
  background: #000000;
  border-top: 3px solid #ad8c41;
  color: #C1C1C1;
}
h4 {
  color: #ad8c41; display: block;
  margin: 0 0 0px 0; font-size: 1.2rem;
}
ul {
  margin: 0; padding: 0; list-style: none;
}
ul li {
  display: block;
}
ul li a {
  display: inline-block;
  padding: 5px 0; cursor: pointer;
}
p {
  margin: 0;
}
.footerCredits a {
  display: inline-block; color: #FFFFFF !important;
  margin: 0 0px; cursor: pointer;
  font-size: 14px;
  padding: 5px 10px; text-transform: uppercase;
}
</style>
