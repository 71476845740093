import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/style.css'
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)






import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

// Register components in your 'main.js'
Vue.component('calendar', Calendar)
Vue.component('date-picker', DatePicker)

const moment = require('moment')
require('moment/locale/de')
Vue.use(require('vue-moment'), {
  moment
});

import axios from 'axios'
var server = location.hostname;
let apiUrl = "";
if(server==='localhost') {
  apiUrl = "http://localhost/works/Funpark/api/public/";
} else if(server==='testdev.flipped-funpark.eu') {
  apiUrl = "https://testapi.flipped-funpark.eu/";
} else {
  apiUrl = "https://api.flipped-funpark.eu/";
}
axios.defaults.baseURL = apiUrl;
axios.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = i18n.locale;
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Headers'] = '*';
  config.headers['Access-Control-Allow-Methods'] = 'POST, GET, PUT, DELETE';
  config.headers['Token'] =  "front";
  config.headers['Authorization'] =  localStorage.getItem("FpCusAuthToken");
  return config;
}, function (error) {
  return Promise.reject(error);
});
Vue.prototype.$http = axios

import router from "@/_router";
import helper from "@/_helpers";

Vue.prototype.$helper = helper
// Vue.prototype.$socket = socket
import i18n from "@/i18n";

import store from "@/_store";
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

import ScrollFixedHeader from 'vuejs-scroll-fixed-header';
Vue.use(ScrollFixedHeader)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const swalOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
  reverseButtons : true
};
Vue.use(VueSweetalert2,swalOptions);

new Vue({
  router,store,i18n,
  render: h => h(App),
}).$mount('#app')
