<template>
  <div class="w-100 text-center py-5">
    <b-icon icon="arrow-clockwise" animation="spin"
            variant="secondary"
            font-scale="6"></b-icon>
    <div class="w-100 py-3"></div>
    <h5>{{$t('loading')}}...</h5>
  </div>
</template>

<script>
export default {
name: "DivLoader"
}
</script>

<style scoped>

</style>