<template>
  <div class="w-100 text-center py-5">
    <img src="../../assets/images/dataNotFound.png"
         style="max-width: 150px" class="img-fluid"/>
    <div class="w-100 py-3"></div>
    <h4 class="text-danger">{{message}}</h4>
  </div>
</template>

<script>
export default {
  name: "ErrorDiv",
  props : {
    message : String
  }
}
</script>

<style scoped>

</style>