<template>
  <div class="clearfix w-100 pb-5">
    <div class="col-12 text-center">
      <div class="row">
        <img src="../../assets/images/Ticket_header.jpg" class="w-100" />
      </div>
      <div class="clearfix w-100"></div>
      <div>
        <img src="../../assets/images/Logo_Ticket_mit_weib.png"
             class="ticketLogo" />
      </div>
    </div>
    <div class="container py-5 text-center" v-if="state==='loading'">
      <div-loader />
    </div>
    <div class="container py-5 text-center" v-else-if="state==='error'">
      <error-div :message="errorMessage" />
    </div>
    <div class="container" v-else>
      <div class="row pb-4 mb-5" v-for="(ticket,index) in tickets" :key="'eachTicketRow'+index">
        <div class="col-12 py-3 text-center">
          <h2 class="text-yellow font-weight-bold pt-4">{{ticket.categoryName}}</h2>
          <div></div>
          <h5 class="text-white pt-2">{{ticket.info}}</h5>
        </div>
        <div class="w-100 py-2"></div>
        <single-ticket v-for="(ticket,index) in ticket.tickets"
                       :offer="offer"
                       :ticket="ticket" :key="'sing'+index" />
      </div>
    </div>

  </div>
</template>

<script>
import SingleTicket from "@/components/Home/SingleTicket";
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
export default {
  name: "AllTickets",
  components: {ErrorDiv, DivLoader, SingleTicket},
  mounted() {
    this.loadTickets();
  },
  methods : {
    loadTickets() {
      this.state = 'loading';
      this.$http.get("front/tickets").then(response=>{
        if(response.data.error===undefined) {
          this.state = 'error';
          this.errorMessage = this.$t('errors.unableToLoadTickets');
        } else if(response.data.error) {
          this.state = 'error';
          this.errorMessage = response.data.message;
        } else {
          this.state = 'success';
          this.tickets = response.data.list;
          this.offer = response.data.offer;
        }
      }).catch(error=>{
        this.state = 'error';
        this.errorMessage = error.toString();
      });
    }
  },
  data() {
    return {
      state : 'loading',
      errorMessage : "",
      tickets : [],
      offer : {}
    }
  }
}
</script>

<style scoped>
.ticketLogo {
  width: 70%;
  max-width: 500px;
  margin-top: -50px;
  margin-bottom: 50px;
}
</style>
