<template>
  <div>
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "@/components/Template/AppHeader";
import AppFooter from "@/components/Template/AppFooter";

export default {
name: "Home",
  components: {AppFooter, AppHeader},
  methods : {
    async checkVoucher(voucherCode) {
      let code = voucherCode.replace(/\s/g,'');
      if(code==="") {
        return  {
          status : false,
          message : this.$t("pleaseEnterVoucherCode"),
          amount : 0
        }
      } else {
        return await this.$http.post('front/validateVoucher', {
          code: code
        }).then(response => {
          if (response.data.error === undefined) {
            return {
              status: false,
              message: this.$t("unableToApplyTryAgain"),
              amount: 0
            }
          } else if (response.data.error) {
            return {
              status: false,
              message: response.data.message,
              amount: 0
            }
          } else {
            return {
              status: true,
              message: response.data.message,
              amount: response.data.amount
            }
          }
        }).catch(() => {
          return {
            status: false,
            message: this.$t("unableToApplyTryAgain"),
            amount: 0
          }
        });
      }
    },
  }
}
</script>

<style scoped>

</style>