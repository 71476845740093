import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/components/Home";
import DashBoard from "@/components/DashBoard";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",  component: Home, meta : { title : 'Home', key : 'home', requiresAuth : false },
        children : [
            { path : "/", name: "Home", component : DashBoard, meta : { title : 'Home'} },
            { path : "/tickets/:code", name: "ticketCategory", component: () => import('./components/Home/TicketCategory'), meta : { title : 'Tickets'} },
            { path : "/buyTicket/:code", name: "buyTicket", component: () => import('./components/Home/BuyTicket/BuyTicket'), meta : { title : 'Buy Ticket'} },
            { path : "/roomBooking", name: "roomBooking", component: () => import('./components/RoomBooking/RoomBooking'), meta : { title : 'Room Booking'} },
            { path : "/bookRoom/:code/:date", name: "bookRoom", component: () => import('./components/RoomBooking/BookRoom'), meta : { title : 'Room Booking'} },
            { path : "/profile", component : () => import('./components/Profile/Profile'),
                children : [
                    { path : "/", name: "profile", component : () => import('./components/Profile/ProfileHome'), meta : { title : "Profile", requireAuth : true} },
                    { path : "orders", name: "profileOrder", component : () => import('./components/Profile/Orders/Orders'), meta : { title : "Orders", requireAuth : true} },
                    { path : "order/:code", name: "profileOrderDetail", component : () => import('./components/Profile/Orders/OrderDetail'), meta : { title : "Order Detail", requireAuth : true} },
                ]
            },
            { path : "/shop", component : () => import('./components/Shop/Shop'),
                children : [
                    { path : "/", name: "shopCategories", component : () => import('./components/Shop/ShopCategories'), meta : { title : 'Shop'} },
                    { path : "checkOut", name: "shopCheckOut", component : () => import('./components/Shop/ShopCheckOut') , meta : { title : 'CheckOut'}},
                    { path : ":code", name: "shopCategoryDetail", component : () => import('./components/Shop/ShopCategoryDetail'), meta : { title : 'Shop'} },
                ]
            },
            { path: "/buy-voucher", name: 'buyVoucher', component: () => import('./components/Voucher/BuyVoucher'), meta : { title : 'Buy Voucher'}   },
            { path: "/download-voucher", name: 'downloadVoucher', component: () => import('./components/Voucher/DownloadVoucher'), meta : { title : 'Download Voucher'}   },
            { path : "*", name: "notFound", component : () => import('./components/PageNotFound'), meta : { title : 'Page not found'} },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to,from,savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth'
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0,
                behavior: 'smooth'
            }
        }
    }
})

const DEFAULT_TITLE = 'Flipped Fun Park';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title+" | "+DEFAULT_TITLE || DEFAULT_TITLE;
    });
});

// router.beforeEach((to,from,next) => {
//     next();
//     window.scrollTo(0, 0);
// })

export default router
